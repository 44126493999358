<template>
  <b-modal
    id="call-modal"
    ref="call-modal"
    title="Thực hiện cuộc gọi trực tiếp cho khách hàng"
    size="l"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    @cancel="resetModal"
    @hide="resetModal"
  >
    <b-overlay :show="loading">
      <b-container fluid class="p-0">
        <!-- <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-text-editors
                label="Lý do"
                placeholder="Câu trả lời"
                name="answer"
                :required="true"
                :value.sync="form.answer"
                data-vv-as="Câu trả lời"
                v-validate="'required'"
                :state="validateState('answer')"
                :invalidFeedback="errors.first('answer')"
              >
              </basic-text-editors>
            </b-col>
          </b-row>
        </form> -->
        <div>
          <div>
            <button @click="login">Login</button>
            Logged in:
            <span :style="{ color: loggedIn ? 'blue' : 'red' }">{{
              loggedUserId
            }}</span>
          </div>

          <div>
            <br />
            Make call from:
            <input
              v-model="fromNumber"
              type="text"
              style="width: 650px"
              placeholder="Enter the phone number purchased from Stringee here to make an App-to-Phone call"
            />
            <br /><br />
            Make call to:
            <input
              v-model="callTo"
              type="text"
              style="width: 200px"
              placeholder="Phonenumber.."
            />
            <br /><br />

            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4 mr-5 ml-5"
              :disabled="!loggedIn"
              @click="makeCall"
            >
              Call
            </button>
            <button
              class="btn btn-warning font-weight-bold text-uppercase px-9 py-4"
              :disabled="!callActive"
              @click="hangupCall"
            >
              Hangup
            </button>
          </div>

          <div v-if="incomingCall">
            Incoming call from: <span>{{ incomingCallFrom }}</span>
            <button @click="answerCall">Answer</button>
            <button @click="rejectCall">Reject</button>
          </div>

          <div>
            <br />
            Call status:
            <span :class="getStatusColorClass(callStatus)">{{
              callStatus
            }}</span>
            | Call type: <span style="color: red">{{ callType }}</span>
          </div>

          <div>
            <video
              id="remoteVideo"
              playsinline
              autoplay
              style="width: 350px"
            ></video>
          </div>
        </div>
      </b-container>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancel"
          tabindex="0"
        >
          Thoát
        </b-button>
        <!-- <b-button
          class="btn btn-success ml-2"
          href="#"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button> -->
      </div>
    </template>
  </b-modal>
</template>

<script>
import { StringeeClient, StringeeCall } from 'stringee-chat-js-sdk';
export default {
  name: 'CallModal',
  components: {},
  props: {
    targetItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    type() {
      return this.popupType;
    },
  },
  watch: {
    id: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.loadData(newVal);
        }
      },
    },
    form: {
      deep: true,
      handler(newVal) {
        if (
          Object.keys(newVal).length &&
          this.initialFrom !== JSON.stringify(newVal)
        ) {
          this.isChange = true;
        }
      },
    },
    targetItem: {
            deep: true,
            handler(val) {
              this.callTo = val.phoneNumber.replace(/^\+/, '');
            },
    },
  },
  data() {
    return {
      detail: {},
      validationState: {},
      error: {},
      form: {},
      loading: false,
      isChange: null,
      initialFrom: null,
      accessToken:'eyJjdHkiOiJzdHJpbmdlZS1hcGk7dj0xIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiJTSy4wLm5GTzhHUjlORE9STlBVWGhkMFB4M1NQWTNrQTlGdm9pLTE3MjM3OTU2MTgiLCJpc3MiOiJTSy4wLm5GTzhHUjlORE9STlBVWGhkMFB4M1NQWTNrQTlGdm9pIiwiZXhwIjoxNzI2Mzg3NjE4LCJ1c2VySWQiOiJ0ZWNoLmFwcCJ9.RIS5lR09RaNZd_8esjoGFKTXeFoio6i_ooCCuUreodg',  // get access token from dashboard stringee 
      stringeeClient: null,
      call: null,
      authenticatedWithUserId: '',
      fromNumber: '842471055742', // Default fromNumber, update as needed
      loggedIn: false,
      callActive: false,
      incomingCall: false,
      incomingCallFrom: '',
      callStatus: '',
      callType: '',
      callTo: null,
      loggedUserId: null
    };
  },

  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    getStatusColorClass(callStatus) {
      switch (callStatus) {
        case 'Answered':
          return 'text-success';
        case 'Call ended':
          return 'text-danger';
        default:
          return 'text-warning'; // Trường hợp mặc định
      }
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        // console.log('target', this.targetItem);
        const choice = await this.handleConfirm();
        if (choice) this.changeStatus(this.targetItem);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.$bvModal.hide('call-modal');
        this.callStatus = 'none';
        this.callType = 'none';
      }
    },
    async changeStatus(item) {
      this.$store.commit('context/setLoading', true);
      // Create a new FormData object
      const formData = new FormData();
      // // Append properties of the item object to formData
      Object.keys(item).forEach((key) => {
        // console.log(key + '====>' + item[key]);
        if (key == 'isDeleted') {
          formData.append('isDeleted', true);
        } else formData.append(key, item[key]);
      });
      // console.log('item', item);
      formData.append('reasonOut', this.form?.answer);

      // formData.append('name', item?.name);
      // formData.append('phoneNumber', '09999000');
      // formData.append('status', 2222);
      // formData.append('dateOfBirth', item?.dateOfBirth);
      try {
        // Send the FormData using the PUT method
        await this.$api.put(`/CustomerReceives/${item.id}`, formData);
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.$emit('load-data');
      } catch (error) {
        if (error) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    resetModal() {
      this.form = {};
      this.initialFrom = {};
      this.isChange = false;
    },
    async handleConfirm() {
      return await this.$swal({
        title: 'Xác nhận?',
        text: `Dữ liệu thay đổi chưa được lưu, xác nhận thoát?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      });
    },
    async handleCancel() {
      if (this.isChange) {
        let confirm = await this.handleConfirm();

        confirm == true ? this.$bvModal.hide('call-modal') : null;
      } else {
        this.$bvModal.hide('call-modal');
      }
      this.callStatus = 'none';
      this.callType = 'none';
    },
    login() {
      this.stringeeClient = new StringeeClient();
      this.settingClientEvents();
      this.stringeeClient.connect(this.accessToken);
    },
    async makeCall() {
      this.call = new StringeeCall(
        this.stringeeClient,
        this.fromNumber,
        this.callTo,
      );
      this.settingCallEvents();
      this.call.makeCall((res) => {
        if (res.r !== 0) {
          this.callStatus = res.message;
        } else {
          this.callType =
            res.toType === 'internal' ? 'App-to-App call' : 'App-to-Phone call';
        }
      });
    },
    answerCall() {
      this.call.answer(() => {
        // console.log('answer res', res);
        this.incomingCall = false;
      });
    },
    rejectCall() {
      this.call.reject(() => {
        // console.log('reject res', res);
        this.callStopped();
      });
    },
    hangupCall() {
      this.call.hangup(() => {
        // console.log('hangup res', res);
        this.callStopped();
      });
    },
    settingClientEvents() {
      // this.stringeeClient.on('connect', () => {
      //   console.log('connected to StringeeServer');
      // });

      this.stringeeClient.on('authen', (res) => {
        // console.log('on authen: ', res);
        if (res.r === 0) {
          this.authenticatedWithUserId = res.userId;
          this.callActive = true; // Enable call button
          this.loggedIn = true;
          this.loggedUserId = this.authenticatedWithUserId;
        } else {
          this.loggedUserId = res.message;
        }
      });

      this.stringeeClient.on('disconnect', () => {
        // console.log('disconnected');
        this.callActive = false; // Disable call button
        this.loggedIn = false;
      });

      this.stringeeClient.on('incomingcall', (incomingcall) => {
        this.call = incomingcall;
        this.settingCallEvents();
        this.incomingCall = true;
        this.incomingCallFrom = incomingcall.fromNumber;
        this.callType = incomingcall.fromInternal
          ? 'App-to-App call'
          : 'Phone-to-App call';
      });
    },
    settingCallEvents() {
      // this.call.on('error', (info) => {
      //   console.log('on error: ' + JSON.stringify(info));
      // });

      // this.call.on('addlocalstream', (stream) => {
      //   console.log('on addlocalstream', stream);
      // });

      this.call.on('addremotestream', (stream) => {
        // console.log('on addremotestream', stream);
        // Reset srcObject to work around minor bugs in Chrome and Edge.
        const remoteVideo = document.getElementById('remoteVideo');
        remoteVideo.srcObject = null;
        remoteVideo.srcObject = stream;
      });

      this.call.on('signalingstate', (state) => {
        // console.log('signalingstate', state);

        if (state.code === 6) {
          // Call ended
          this.incomingCall = false;
          this.callStopped();
        }

        if (state.code === 5) {
          // Busy here
          this.callStopped();
        }

        this.callStatus = state.reason;
      });

      // this.call.on('mediastate', (state) => {
      //   console.log('mediastate ', state);
      // });

      // this.call.on('info', (info) => {
      //   console.log('on info', info);
      // });

      this.call.on('otherdevice', (data) => {
        // console.log('on otherdevice:' + JSON.stringify(data));

        if (
          (data.type === 'CALL_STATE' && data.code >= 200) ||
          data.type === 'CALL_END'
        ) {
          this.incomingCall = false;
        }
      });
    },
    callStopped() {
      this.callActive = false; // Disable call button
      setTimeout(() => {
        this.callStatus = 'Call ended';
      }, 1500);
    },
  },
  mounted() {
    this.login();
    this.callStatus = 'none';
    this.callType = 'none';
  },
};
</script>

<style lang="scss"></style>
